



























































































































import api from "@/api/index"; //ABP API接口
import { Vue, Component } from "vue-property-decorator";

import { WarehouseDto, WarehouseLocationDto } from "@/api/appService";
import PagedTableView from "@/components/PagedTableView.vue";
import WareHouseEdit from "@/views/warehouse/warehouseLocation/edit.vue";
import LocationEdit from "@/views/warehouse/warehouseLocation/locationEdit.vue";

@Component({
  name: "WareHouseLocationList",
  components: {
    LocationEdit,
    WareHouseEdit,
    PagedTableView,
  },
})
export default class WareHouseLocationList extends Vue {
  detail: WarehouseDto = {};
  locationId = 0;
  warehouseId = 0;
  queryForm = {
    status: undefined,
    warehouseId: 0,
  };

  created() {
    if (this.$route.query.id) {
      this.warehouseId = Number(this.$route.query.id);
      this.queryForm.warehouseId = this.warehouseId;
      api.wareHouse
        .get({
          id: this.warehouseId,
        })
        .then((res) => {
          this.detail = res!;
        });
    }
  }

  // 获取表数据
  fetchData(params: any) {
    return api.wareHouseLocation.getAll(params);
  }

  // 新建
  handleCreate() {
    this.locationId = 0;
    (this.$refs.editForm as any).show = true;
  }

  // // 查看
  // handleDetail(index: number, row: WarehouseLocationDto) {
  //   // console.log("id=" + row!.id);
  //   this.editId = row.id!;
  //   this.$router.push({
  //     name: "VolunteerDetail",
  //     params: { id: row.volunteerId!.toString() },
  //   });
  // }

  // 编辑
  handleEdit(index: number, row: WarehouseLocationDto) {
    this.locationId = row!.id!;
    (this.$refs.editForm as any).show = true;
    (this.$refs.editForm as any).form = row;
  }

  // 删除
  handleDelete(index: number, row: WarehouseLocationDto) {
    this.$confirm(
      (this as any).$l.getLanguageText("basic.deleteTip").toString(),
      (this as any).$l.getLanguageText("basic.tip").toString()
    ).then(async () => {
      api.wareHouseLocation
        .delete({
          id: row.id,
        })
        .then(() => {
          this.$message({
            type: "success",
            message: (this as any).$l
              .getLanguageText("basic.deleteSuccess")
              .toString(),
          });
        });
    });
  }
}
